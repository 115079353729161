import React, { Fragment, useState } from 'react';
import { push } from 'connected-react-router';
import { Form, Field } from 'react-final-form';
import { Card, CardActions, TextField } from '@material-ui/core';
import { lightBlue } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { useTranslate, useNotify } from 'ra-core';
import { Button } from 'react-admin';
import { connect, useDispatch } from 'react-redux';
import { resetService } from './resetService';
import Logo from './Logo';

const useStyles = makeStyles(theme => ({
	main: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	card: {
		minWidth: 400,
		marginTop: theme.spacing(20),
		backgroundColor: lightBlue[50]
	},
	icon: {
		marginTop: theme.spacing(1),
		backgroundColor: theme.palette.secondary.light
	},
	form: {
		padding: theme.spacing(2)
	},
	input: {
		marginTop: theme.spacing(1)
	},
	actions: {
		padding: theme.spacing(2)
	},
	loginProgress: {
		marginRight: theme.spacing(2)
	},
	backBtn: {
		marginTop: theme.spacing(2)
	}
}));

const renderInput = ({
	meta: { touched, error } = {},
	input: { ...inputProps },
	...props
}) => (
	<TextField
		error={!!(touched && error)}
		helperText={touched && error}
		{...inputProps}
		{...props}
		fullWidth
	/>
);

const ForgotPassword = () => {
	const [loading, setLoading] = useState(false);
	const [sent, setSent] = useState("");
	const classes = useStyles();
	const dispatch = useDispatch();
	const translate = useTranslate();
	const notify = useNotify();

	const handleSubmit = (values) => {
		setLoading(true);

		return resetService.sendPasswordResetEmail(values.email)
		.then(() => {
			setSent(values.email);
		})
		.catch((error) => {
			if (!error.response) {
				notify('eiss.no_response', 'warning');
			}
		})
		.finally(() => {
			setLoading(false);
		});
	};

	const validate = (values) => {
		const errors = {};
		if (!values.email) {
			errors.email = translate('ra.validation.required');
		}
		return errors;
	};

	return (
		<Form
			onSubmit={handleSubmit}
			validate={validate}
			render={({ handleSubmit, form }) => (
				<form onSubmit={handleSubmit} noValidate>
					<div className={classes.main}>
						<Card className={classes.card}>
							<Logo />
					{(sent === "") ? 
						<Fragment>
							<div className={classes.form}>
								<div className={classes.input}>
									<Field
										name='email'
										component={renderInput}
										label='Enter your recovery email address'
										disabled={loading}
										inputProps={{ autoComplete: 'off' }}
									/>
								</div>
							</div>
							<CardActions className={classes.actions}>
								<Button
									label='Send recovery message'
									variant='contained'
									type='submit'
									disabled={loading}
									fullWidth
								>
									{loading ? (
										<CircularProgress
											className={classes.loginProgress}
											size={25}
											thickness={2}
										/>
									) : null}
								</Button>
							</CardActions>
						</Fragment>
					: 
						<Fragment>
							<div className={classes.form}>
								<Typography variant="body2" >
									An email has been sent to the following address: 
									<br/>
									<b>{sent}</b>
									<br/><br/>
									Please follow the instructions<br/>
									to complete your password recovery.
								</Typography>
							</div>
						</Fragment>
					}
				</Card>

				<Button 
					className={classes.backBtn}
					label='back to login'
					onClick={() => {
						form.reset();
						dispatch(push("/login"));
					}}
				/>
					</div>
				</form>
			)}
		/>
	);
}

const ForgotPasswordWithTheme = (props) => (
	<ThemeProvider theme={props.theme}>
		<ForgotPassword {...props} />
	</ThemeProvider>
);

const enhForgotPasswordWithTheme = connect(null, {
	push
})(ForgotPasswordWithTheme);

export default enhForgotPasswordWithTheme;
